import React from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { useEventContext } from '../../../contexts/EventProvider'
import AdminHeader from './AdminHeader'
import MainHeader from './Header'
import Announcements from '../../molecules/Announcements'

const Header = () => {
	const { isOwner } = useEventContext()
	const location = useLocation()

	/**
	 * Match routes where Header should be hidden
	 * This can be improved if we have more views to match.
	 */
	const isMatch = matchPath('/e/:uuid/*', location.pathname) // Match public event route' root

	const shouldHideHeader = isMatch && !isOwner

	if (shouldHideHeader) {
		// console.log('return announcements')
		return (
			<>
				<AdminHeader />
			</>
		)
	} else {
		// return <>Main header</>
		return <MainHeader />
	}
}

export default Header
