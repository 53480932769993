import React, { useEffect, useState } from 'react'
import { Box, useDisclosure } from '@chakra-ui/react'
import loadable from '@loadable/component'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import useScrollPosition from '@react-hook/window-scroll'
const SiteStatus = loadable(() => import(/* webpackPrefetch: true */ '../src/SiteStatus'))

const AdminHeader = () => {
	/** Retrieve Authed User */
	const { role, isImpersonated } = useAuthContext()
	/** Is the admin bar visible ? */
	const [isAdminBarVisible, setAdminBarVisibility] = useState(false)
	/** Should we show the toolbox ? */
	const toolbox = useDisclosure()
	/** Should we show the Sticky Toolbox ? */
	const stickyToolbox = useDisclosure()
	/** Scroll position  */
	const scrollY = useScrollPosition(120 /*fps*/)

	/** Update admin bar visibility based on user 'role' */
	useEffect(() => {
		if (role == 'editor' || role == 'admin' || isImpersonated) {
			setAdminBarVisibility(true)
		} else setAdminBarVisibility(false)
	}, [role, isImpersonated])

	/** Adjust visibility of some elements based on the scroll position */
	useEffect(() => {
		if ((role == 'editor' || role == 'admin' || isImpersonated) && scrollY > 80) {
			stickyToolbox.onOpen()
		} else {
			stickyToolbox.onClose()
		}
	}, [scrollY])

	/** Define conditional styling for the box */
	const toolboxStyles = {
		position: stickyToolbox.isOpen ? 'fixed' : 'relative',
		top: stickyToolbox.isOpen ? '0' : 'inherit',
		zIndex: 1000,
	}

	return (
		<>
			{stickyToolbox.isOpen && <Box h="48px" />}
			{isAdminBarVisible && (
				<SiteStatus
					toolboxStyles={toolboxStyles}
					showToolbox={toolbox.isOpen}
					setToolboxVisibility={toolbox.onToggle}
				/>
			)}
		</>
	)
}

export default AdminHeader
