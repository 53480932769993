import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'

const baseStyles = {
	position: 'fixed',
	left: 0,
	right: 0,
	bottom: 0,
	top: '80px',
	backgroundColor: 'white',
	w: 'full',
	m: 0,
	pt: 0,
	zIndex: 20000000000,
	overflowY: 'scroll',
	px: '1.5rem',
}

/**
 * "Bridge" component providing the styling/positioning for a Mobile Navigation
 * This allow us to define the content of the fixed mobile menu higher up in the tree
 *  */
const MobileMenu = ({ children }) => {
	return (
		<Box sx={baseStyles}>
			{/** Insert children elements if we want to pass in a custom view */}
			{children && children}
		</Box>
	)
}

MobileMenu.propTypes = {
	children: PropTypes.any.isRequired,
}

export default MobileMenu
