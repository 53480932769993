/** Define default Base menu */
export default [
	{ id: 'help', title: 'Help Center', to: 'https://help.vidday.com/', relative: false },
	{ id: 'faq', title: 'FAQ', to: 'https://www.vidday.com/about/faq', relative: false },
	{ id: 'pricing', title: 'Pricing', to: 'https://www.vidday.com/pricing', relative: false },
	{ id: 'privacy-policy', title: 'Privacy Policy', to: 'https://www.vidday.com/privacy-policy', relative: false },
	{ id: 'terms-of-use', title: 'Terms of Use', to: 'https://www.vidday.com/terms-of-use', relative: false },
]

/**
 * This is the menu of the home page that is used for testing only.
 * This should be removed once the new menu implementation has been
 * moved to Bit */
export const home = [
	{ id: 'home', title: 'Home', to: '/', relative: true },
	{
		id: 'occasions',
		title: 'Popular Occasions',
		to: '#',
		heding: true,
		children: [
			{ id: 'birthday', title: 'Birthday', to: '/video-maker/birthday-video', relative: true },
			{
				id: 'quarantinedbirthday',
				title: 'Quarantined Birthday',
				to: '/video-maker/quarantined-birthday-video',
				relative: true,
			},
			{ id: 'wedding', title: 'Wedding', to: '/video-maker/wedding-video', relative: true },
			{ id: 'anniversary', title: 'Anniversary', to: '/video-maker/anniversary-video', relative: true },
			{ id: 'retirement', title: 'Retirement', to: '/video-maker/retirement-video', relative: true },
			{ id: 'getwell', title: 'Get Well', to: '/video-maker/get-well-video', relative: true },
			{ id: 'graduation', title: 'Graduation', to: '/video-maker/graduation-video', relative: true },
			{ id: 'holiday', title: 'Holiday Greetings', to: '/video-maker/holiday-video', relative: true },
		],
	},
	{
		id: 'about',
		title: 'About',
		to: '#',
		heding: true,
		children: [
			{ id: 'whoweare', title: 'Who We Are', to: '/about/who-we-are', relative: true },
			{ id: 'howitworks', title: 'How It Works', to: '/about/how-it-works', relative: true },
			{ id: 'faq', title: 'FAQ', to: '/about/faq', relative: true },
		],
	},
	{
		id: 'more',
		title: 'More',
		to: '#',
		heding: true,
		children: [
			{ id: 'pricing', title: 'Pricing', to: '/pricing', relative: true },
			{ id: 'reviews', title: 'Reviews', to: '/reviews', relative: true },
			{ id: 'themes', title: 'Themes', to: '/themes', relative: true },
			{ id: 'blog', title: 'Blog', to: 'https://www.vidday.com/blog', relative: false },
		],
	}, // if no 'to', then it's only for a dropdown
]
