import React from 'react'
import useHandleLogoutClick from '../../../../hooks/useHandleLogoutClick'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import { Flex, Text, chakra, Divider } from '@chakra-ui/react'

const buttonStyles = {
	py: '0.1rem',
	fontSize: '1rem',
	fontWeight: '500',
	width: 'full',
	_hover: {
		cursor: 'pointer',
		color: '#FF1C63',
	},
}

const LogoutBlock = () => {
	const { email } = useAuthContext()

	/** Handle logout click */
	const handleLogoutClick = useHandleLogoutClick()

	return (
		<>
			<Flex direction="column" my="1.5rem" textAlign={['center', 'right']}>
				<chakra.a onClick={handleLogoutClick} sx={buttonStyles}>
					Log Out
				</chakra.a>
			</Flex>

			<Divider variant="dashed" my="1rem" />

			<Text fontSize="0.875rem" color="#B5BCC3" textAlign={['center', 'right']}>
				Logged in as <strong>{email}</strong>
			</Text>
		</>
	)
}

export default LogoutBlock
