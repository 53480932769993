import React, { useState } from 'react'

/** Custom Hook that manage logic for a toggle */
const useToggle = (initialValue = false) => {
	const [value, setValue] = useState(initialValue)

	const toggle = React.useCallback(() => {
		setValue((v) => !v)
	}, [])

	return [value, toggle]
}

export default useToggle
