import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
import loadable from '@loadable/component'
import { Box, Container } from '@chakra-ui/react'
import NavMenu, { MainMenu } from './src/NavMenu'
import MobileMenu from './src/MobileMenu'
import useToggle from '../../../hooks/useToggle'
import useWindowSize from '../../../hooks/useWindowSize'
import useLockBodyScroll from '../../../hooks/useLockBodyScroll'
import { useAuthContext } from '../../../contexts/AuthProvider'
import AdminHeader from './AdminHeader'

const Burger = loadable(() => import(/* webpackPrefetch: true */ './src/Burger'))
const Notifications = loadable(() => import(/* webpackPrefetch: true */ './src/Notifications'))
const ContentCover = loadable(() => import(/* webpackPrefetch: true */ './src/ContentCover'))
const Logo = loadable(() => import(/* webpackPrefetch: true */ './src/Logo'))
const Announcements = loadable(() => import(/* webpackPrefetch: true */ '../../molecules/Announcements'))
const LogoutBlock = loadable(() => import(/* webpackPrefetch: true */ './src/LogoutBlock'))
const InlineMenu = loadable(() => import(/* webpackPrefetch: true */ './src/InlineMenu'))

const styledTransition = {
	'&.active': {
		visibility: 'visible',
		transition: 'all 200ms ease-in',
	},
	'&.hidden': {
		visibility: 'hidden',
		transition: 'all 200ms ease-out',
		transform: 'translate(0, -100%)',
	},
}

const Transition = ({ children }) => {
	return <Box sx={styledTransition}>{children}</Box>
}

/** Functional Component allowing to conditionally lock the body from scrolling  */
const LockBodyScroll = () => {
	/** Prevent document body from scrolling when mounted */
	useLockBodyScroll()
	return <></>
}

const Header = ({ displayNavigation }) => {
	const location = useLocation()
	const [currentLocation, setCurrentLocation] = useState(location.pathname)

	/** Hold state of the menu navigation visibility */
	const [isToggle, setToggleFunc] = useToggle(false)
	/** are we in mobile mode (based on window size - Screen Size to Swap from Desktop to Mobile) ? */
	const [isMobileMode, setMobileMode] = useState(false)
	/** Update link to home page for logo */
	const [homeLink, setHomeLink] = useState('https://www.vidday.com')
	/** Retrieve Authed User */
	const { isAuthenticated } = useAuthContext()
	/** Retrieve window size (width/height) */
	const size = useWindowSize()

	/** Close navigation panel when location path changes */
	useEffect(() => {
		if (location.pathname != currentLocation) {
			setCurrentLocation(location.pathname)
			isToggle ? setToggleFunc() : null
		}
	}, [location.pathname])

	/** Overwrite setToggle function by enforcing a scroll to top when the menu should be toggled */
	const setToggle = () => {
		setToggleFunc()
		isToggle === false && window != undefined ? window?.scrollTo(0, 0) : null
	}

	/** Update the mobile mode based on the window size */
	useEffect(() => {
		size.width <= 792 && setMobileMode(true)
		size.width > 792 && setMobileMode(false)
	}, [size])

	/** If the user is authenticated, redirect to dashboard when clicking the logo */
	useEffect(() => {
		isAuthenticated === true && setHomeLink('/dashboard')
	}, [isAuthenticated])

	return (
		<Transition>
			{isToggle === true && <LockBodyScroll />}

			<Box
				sx={{
					width: 'full',
					height: '80px',
					display: 'block',
					mb: '0',
					position: 'relative',
					borderBottom: '1px',
					borderColor: 'gray.100',
					backgroundColor: 'white',
					zIndex: 2147483638,
				}}>
				<Container maxW="container.xl" height="80px">
					<Box
						sx={{
							position: 'relative',
							h: 'full',
							py: '0',
							px: ['1rem', 'auto'],
						}}>
						<Logo
							target={homeLink}
							altValue="Create Your Own Gift Video for Special Occasions | VidDay 🎁"
						/>

						{isAuthenticated && <Notifications />}

						{(displayNavigation !== 'inline' || isMobileMode === true) && (
							<Burger setNavToggle={setToggle} isActive={isToggle} />
						)}

						{displayNavigation === 'panel' && !isMobileMode && (
							<>
								<NavMenu isActive={isToggle} setNavToggle={setToggle} NavLinkComponent={NavLink} />

								{isToggle && <ContentCover isActive={isToggle} onClick={setToggle} />}
							</>
						)}
					</Box>
				</Container>
			</Box>

			{isMobileMode && isToggle && (
				<MobileMenu isMobileMode={isMobileMode} handleToggleNav={setToggle}>
					<MainMenu NavLinkComponent={NavLink} onClick={setToggle} />

					<InlineMenu isMobileMode={isMobileMode} handleClose={setToggle} />

					{isAuthenticated && <LogoutBlock />}
				</MobileMenu>
			)}

			<AdminHeader />
		</Transition>
	)
}

Header.defaultProps = {
	displayNavigation: 'panel',
}

Header.propTypes = {
	/**
	 * Display wether the main navigation should be inline along the logo,
	 * or if the navigation should be hidden through a panel that opens from the right.
	 */
	displayNavigation: PropTypes.oneOf(['inline', 'panel']).isRequired,
}

export default Header
