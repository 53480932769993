import React from 'react'
import PropTypes from 'prop-types'
import LogoutBlock from './LogoutBlock'
import data from '../data'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import { chakra, Box, Flex, Button, VStack, Divider } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export const buttonStyles = {
	py: '0.1rem',
	fontSize: '1rem',
	fontWeight: '500',
	width: 'full',
	_hover: {
		color: '#0F66D0',
	},
}

const buttonStylesMain = { ...buttonStyles, fontWeight: '600', fontSize: '1.125rem' }

/* **************************************
 *  PANEL MENU
 * ************************************ */

export const MainMenu = ({ onClick = null }) => {
	/** Retrieve the role of the user and wether the user is authenticated or not */
	const { role, isAuthenticated } = useAuthContext()

	/** If user is authenticated, return a different menu */
	if (isAuthenticated) {
		return (
			<Box onClick={onClick}>
				<Flex direction="column" my="1.5rem">
					<Button as="a" href="/start-a-vidday">
						Start a New VidDay
					</Button>
				</Flex>

				<Divider variant="dashed" my="1rem" />

				<VStack
					flexDirection="column"
					spacing="0.5rem"
					my="1.5rem"
					textAlign={['center', 'right']}
					alignItems={['center', 'flex-end']}>
					{role === 'admin' && (
						<chakra.a href="https://admin.share.vidday.com/" target="_blank" sx={buttonStylesMain}>
							Admin
						</chakra.a>
					)}
					<chakra.a as={Link} to="/dashboard" sx={buttonStylesMain}>
						VidDays
					</chakra.a>
					<chakra.a as={Link} to="/settings/profile" sx={buttonStylesMain}>
						Account
					</chakra.a>
				</VStack>
			</Box>
		)
	}

	return (
		<>
			<Flex direction="column" my="1.5rem">
				<Button as="a" href="/start-a-vidday">
					Start a New VidDay
				</Button>
			</Flex>

			<Divider variant="dashed" my="1rem" />

			<VStack
				flexDirection="column"
				spacing="0.5rem"
				my="1.5rem"
				textAlign={['center', 'right']}
				alignItems={['center', 'flex-end']}>
				{/* {role === 'admin' && (
					<chakra.a as={Link} to="/admin" sx={buttonStylesMain}>
						Admin
					</chakra.a>
				)} */}
				<chakra.a as="a" href="/auth/login" sx={buttonStylesMain}>
					Log In
				</chakra.a>
				<chakra.a as="a" href="/auth/register" sx={buttonStylesMain}>
					Create Account
				</chakra.a>
			</VStack>
		</>
	)
}

MainMenu.propTypes = {
	/** OnClick event handler */
	onClick: PropTypes.func,
	/* Hack until router can be passed as context */
	NavLinkComponent: PropTypes.object.isRequired,
}

const _styles = {
	position: 'fixed',
	clear: 'both',
	mt: '80px',
	visibility: 'hidden',
	width: '320px',
	h: '100vh',
	right: '0',
	zIndex: '100000',
	overflow: 'hidden',
	px: '1rem',
	backgroundColor: 'white',
	textAlign: 'right',
	transform: 'translateX(356px)',
	transition: 'transform 400ms ease-in-out',
	overflowY: 'scroll',
	pb: '96px',
}

const NavMenu = ({ isActive, NavLinkComponent }) => {
	const { isAuthenticated } = useAuthContext()

	/** Generate item views of the base menu items (excludes buttons, and admin navigation)  */
	let itemViews = data.map((el, i) => {
		return (
			<chakra.a href={el.to} key={i} sx={buttonStyles}>
				{el.title}
			</chakra.a>
		)
	})
	const styles = isActive
		? {
				..._styles,
				...{
					visibility: 'visible',
					transform: 'translateX(0)',
				},
		  }
		: _styles

	return (
		<Box id="desktop_nav" sx={styles}>
			{/** Main Menu, differs based on authentication */}
			<MainMenu NavLinkComponent={NavLinkComponent} />

			<Divider variant="dashed" my="1rem" />

			{/* <StyledMenuDivider /> */}

			<VStack flexDirection="column" spacing="0.25rem" my="1.5rem" alignItems="flex-end">
				{itemViews}
			</VStack>

			<Divider variant="dashed" my="1rem" />
			{/* <StyledMenuDivider /> */}

			{/** Logout Block that handles logout under the hood */}
			{isAuthenticated && <LogoutBlock />}
		</Box>
	)
}

NavMenu.propTypes = {
	/** Should the panel be visible? */
	isActive: PropTypes.bool.isRequired,
	/* Toggle callback method to switch the visibility of the menu  */
	setNavToggle: PropTypes.func.isRequired,
	/* Hack until router can be passed as context */
	NavLinkComponent: PropTypes.object.isRequired,
}

NavMenu.defaultProps = {
	isActive: false,
}

export default NavMenu
